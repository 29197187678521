<template>
    <div class="contentsSpd">
        <div >
      <div style="height:1px;"></div>
     <div class="Index_UserInfo_hbody">
      <div class="UserInfo_hbody">
        <div class="top">
          <span style="color:rgb(0, 0, 0); font-weight:700;font-size:22px;padding-right:5px;">10</span>
          <span style="color:rgb(0, 0, 0); font-weight:700;font-size:20px;">某某</span>
        </div>
        <div class="bottom">
         
          <div>
             <span>电话:</span>
             <span style="color:rgb(0, 141, 255);font-size:16px;">86687723</span>
          <van-icon name="phone" color="rgb(61, 96, 239)"/>
          </div>
          </div>
        </div>
      </div>
      <div class="ServiceRemarks">
          <span>服务备注：</span><br>
          <span></span>
      </div>
    </div>
    </div>
</template>

<script>
export default {
    data () {
        return {

 
        }
    }
}
</script>
 
<style lang = "less" scoped>
* {
  margin: 0;
  padding: 0;
}
.contentsSpd{
    height: 100vh;
  /* margin-bottom:10px; */
    background-color: rgb(245,245,245);
}
.Index_UserInfo_hbody{
  background-color: #fff;
  margin:10px;
  padding: 10px;
  border: 1px solid rgb(245,245,245);
  border-radius: .625rem /* 10/16 */;
  box-shadow: #ccc;
  .UserInfo_hbody{
   padding: 10px;
   .top{
     text-align: left;
   }
   .bottom{
     display: flex;
     justify-content: space-between;
     padding: 10px 0;
   }
    
  }
}
.ServiceRemarks{
    text-align: left;
    padding: 0 15px;
}
</style>